/**
 * local icons
 */
require.context('./icons/', true, /\.svg$/);

/**
 * Bootstrap icons
 * https://icons.getbootstrap.com/
 */
import 'bootstrap-icons/icons/telephone.svg';
import 'bootstrap-icons/icons/envelope.svg';
import 'bootstrap-icons/icons/x.svg';
import 'bootstrap-icons/icons/list.svg';
import 'bootstrap-icons/icons/arrow-right.svg';
import 'bootstrap-icons/icons/chevron-down.svg';
import 'bootstrap-icons/icons/download.svg';

/**
 * Twig
 * RegEx: load only Twig-files NOT starting with an underscore (since those are partials)
 */
require.context('./twig', true, /(?:(?:(?:[/]?)(?:[^/]+\/)+)([^_]+\.twig))/);

/**
 * JS
 * Just include whatever you need
 */
import './js/bootstrap';
import './js/header-height';
