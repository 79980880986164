const headerEl = document.querySelector('header');
import { throttle } from 'lodash';

try {
  if (headerEl) {
    const setHeaderHeightVar = () => {
      document.documentElement.style.setProperty(
        '--header-height',
        headerEl.clientHeight / 16 + 'rem'
      );
    };

    setHeaderHeightVar();
    window.addEventListener(
      'resize',
      throttle(() => {
        setHeaderHeightVar();
      }, 750)
    );
  }
} catch (error) {
  console.log(error);
}
